<template>
  <div v-if="hasRole" class="display-1 text-center">
    <br />
    <br />Welcome to {{ appName }} Portal
  </div>
  <div v-else class="display-1 text-center grey--text text--darken-2">
    <br /> <span class="red--text">Sorry!</span> your account doesn't have any roles and permission to access portal functionality, please contact
    <a :href="`mailto:${ email }`">{{ email }}</a>.

  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import {mobileAppName} from '@/constants/constants'

export default {
  name: 'home',
  components: {},
  data() {
    return {
      email: 'support@medcury.health'
    }
  },
  computed: {
    appName: function () {
      return mobileAppName
    },
    hasRole: function () {
      return this.roles
        ? Object.keys(this.roles).length > 0
        : false
    },
    ...mapGetters('auth', ['getUser']),
    ...mapState('auth', ['roles']),
  }
}
</script>
